/*!
 * _const.js
 */
//# =================================================================
//# 変数定義
//# =================================================================
// スクロール位置を保持する
var startPos_hed = 0;
var startPos_scr = 0;
var mobile_flg = window.ontouchstart !== null ? false : true;
var resize_handler = mobile_flg ? "orientationchange" : "resize";
var clickEventType = mobile_flg ? "click" : "hover";
/*!
 * _settings.js
 */
// # =================================================================
// # loadイベント（ready)
// # =================================================================
$(function () {
  set_001(window.navigator.userAgent.toLowerCase(), document.documentElement);
  set_002();
});
// # =================================================================
// # mousewheelイベント
// # =================================================================
$("body").on("mousewheel", function () {
  set_003(window.navigator.userAgent.toLowerCase(), event);
});
// # =================================================================
// # クリックイベント
// # =================================================================
$('a[href^="#"]').click(function () {
  set_004($(this));
});
/**google analytics**************************************

 ********************************************************/
(function (i, s, o, g, r, a, m) {
  i["GoogleAnalyticsObject"] = r;
  (i[r] =
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }),
    (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(
  window,
  document,
  "script",
  "https://www.google-analytics.com/analytics.js",
  "ga"
);

ga("create", "UA-92547959-1", "auto");
ga("send", "pageview");
// # =================================================================
// # htmlタグに使用するブラウザをクラス名として付与する
// # =================================================================
function set_001(userAgent, htmlElement) {
  if (userAgent.indexOf("msie") != -1 || userAgent.indexOf("trident") != -1) {
    htmlElement.className += " ie";
  } else if (userAgent.indexOf("edge") != -1) {
    htmlElement.className += " edge";
  } else if (
    userAgent.indexOf("chrome") !== -1 &&
    userAgent.indexOf("edge") === -1 &&
    userAgent.indexOf("opr") === -1
  ) {
    htmlElement.className += " chrome";
  } else if (userAgent.indexOf("firefox") !== -1) {
    htmlElement.className += " firefox";
  } else if (userAgent.indexOf("safari") !== -1) {
    htmlElement.className += " safari";
  } else if (userAgent.indexOf("opera") !== -1) {
    htmlElement.className += " opera";
  }
}
// # =================================================================
// # bodyタグからpreloadクラスを除去する
// # ※preloadクラス：transitionクラスをキャンセル
// # =================================================================
function set_002() {
  $("body").removeClass("preload");
}
// # =================================================================
// # パララックス時のガタつきを止める（IE/Edge）
// #（原因）IE/Edgeのバグ。ブラウザ参照時の「スムーズスクロール」という
// # 機能が悪さをしている
// #（対策）IE/Edgeの場合にスムーズスクロールを強制的に無効にする
// # =================================================================
function set_003(userAgent, e) {
  if (
    userAgent.indexOf("msie") != -1 ||
    userAgent.indexOf("trident") != -1 ||
    userAgent.indexOf("edge") != -1
  ) {
    e.preventDefault();
    var wd = e.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  }
}
// # =================================================================
// #で始まるアンカーをクリックした場合に処理
// # =================================================================
function set_004(obj) {
  // スクロールの速度
  var speed = 400;
  // ミリ秒
  // アンカーの値取得
  var href = obj.attr("href");
  // 移動先を取得
  var target = $(href == "#" || href == "" ? "html" : href);
  // 移動先を数値で取得
  var position = target.offset().top;
  // スムーススクロール
  $("body,html").animate(
    {
      scrollTop: position,
    },
    speed,
    "swing"
  );
}
/*!
 * _header.js
 */
//# =================================================================
//# loadイベント（ready)
//# =================================================================
$(function () {
  hed_init();
});
//# =================================================================
//# ブラウザサイズ変更時イベント
//# =================================================================
$(window).resize(function () {
  // スマートフォンの場合はorientationchangeイベントを監視する
  // スマートフォン以外はresizeイベントを監視する
  window.addEventListener(resize_handler, hed_init);
});
//# =================================================================
//# スクロールイベント
//# =================================================================
$(window).scroll(function () {
  hed_001($("#cmn-header"), $(window).scrollTop());
});
//# =================================================================
//# クリックイベント
//# =================================================================
$("#hamburger").click(function () {
  hed_002($("#cmn-header"), "is-open");
  hed_002($("main"), "is-open");
  if (!$("html").hasClass("chrome")) {
    hed_002($("main"), "is-blur");
  }
});
$("ul.menu_1st-lv li > a").click(function () {
  hed_init();
});
//# =================================================================
//# マウスオーバーイベント
//# =================================================================
if (mobile_flg) {
  $("ul.menu_1st-lv > li")
    .mouseover(function () {
      hed_004($(this), "ul.menu_2nd-lv");
    })
    .click(function () {
      hed_003($(this), "ul.menu_2nd-lv");
    });
} else {
  $("ul.menu_1st-lv > li").hover(function () {
    hed_003($(this), "ul.menu_2nd-lv");
  });
}
//#=================================================================
//# function : hed_init
//# Contents : 初期処理
//# =================================================================
function hed_init() {
  // is-openクラスを除去する
  $("#cmn-header").removeClass("is-open");
  $("main").removeClass("is-open");
  $("main").removeClass("is-blur");
  // hideクラスを除去する
  $("#cmn-header").removeClass("hide");
}
//# =================================================================
//# function : hed_001
//# Contents : スクロール方向によるobjにclsの付与/clsの除去の制御
//# =================================================================
function hed_001(obj, scroll_top) {
  var winScrollTop = scroll_top < 0 ? 0 : scroll_top;
  if (obj.hasClass("is-open")) {
    obj.removeClass("hide");
  } else if (winScrollTop > startPos_hed) {
    obj.addClass("hide");
  } else {
    obj.removeClass("hide");
  }
  startPos_hed = winScrollTop;
}
//# =================================================================
//# function : hed_002
//# Contents : objをクリックしたときのclsの付与/除去
//# =================================================================
function hed_002(obj, cls) {
  if (obj.hasClass(cls)) {
    obj.removeClass(cls);
  } else {
    obj.addClass(cls);
  }
}
//# =================================================================
//# function : hed_003
//# Contents : objをマウスオーバーした時のイベント
//# =================================================================
function hed_003(obj, child) {
  obj.children(child).stop().slideToggle();
}
//# =================================================================
//# function : hed_004
//# Contents : objをマウスオーバーした時のイベント
//# =================================================================
function hed_004(obj, child) {
  obj.children(child).stop().slideDown();
  obj.siblings().children(child).slideUp();
}
/*!
 * __scrollTop.js
 */
//# =================================================================
//# loadイベント（ready)
//# =================================================================
$(function () {
  scr_init();
});
//# =================================================================
//# ブラウザサイズ変更時イベント
//# =================================================================
$(window).resize(function () {
  // スマートフォンの場合はorientationchangeイベントを監視する
  // スマートフォン以外はresizeイベントを監視する
  window.addEventListener(resize_handler, scr_init);
});
//# =================================================================
//# スクロールイベント
//# =================================================================
$(window).scroll(function () {
  scr_001($("#scroll-top"), $(window).scrollTop());
});
//#=================================================================
//# function : scr_init
//# Contents : 初期処理
//# =================================================================
function scr_init() {
  // hideクラスを除去する
  $("#scroll-top").removeClass("hide");
}
//# =================================================================
//# function : hed_001
//# Contents : スクロール方向によるobjにclsの付与/clsの除去の制御
//# =================================================================
function scr_001(obj, scroll_top) {
  var winScrollTop = scroll_top < 0 ? 0 : scroll_top;
  if (winScrollTop < startPos_scr) {
    obj.addClass("hide");
  } else {
    obj.removeClass("hide");
  }
  startPos_scr = winScrollTop;
}
